import React from "react";
import { Grid, Typography } from "@mui/material";
import {
  DatePicker,
  EmailInput,
  FormStyled,
  Input,
  PhoneInput,
  Select,
  TextArea,
} from "@homesusa/form";
import { AnimatedContainer, SectionBox } from "@homesusa/core";
import { AddressSection, GeneralSection } from "core/components/form-update";

export function EmailForm(): JSX.Element {
  return (
    <AnimatedContainer>
      <FormStyled>
        <Typography sx={{ width: "100%", my: 3 }} variant="h2">
          Email Information
        </Typography>
        <form data-testid="Email-form">
          <Grid container>
            <Grid item xs={6}>
              <Grid container rowGap={1} wrap="nowrap" flexDirection={"column"}>
                <GeneralSection />
                <AddressSection />
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <Grid container columnGap={3} wrap="nowrap">
                <Grid item xs={12}>
                  <SectionBox title="Lead Info">
                    <Grid container>
                      <Grid item xs={6}>
                        <DatePicker name="transferedOn" />
                      </Grid>
                      <Grid item xs={6}>
                        <Select name="source" />
                      </Grid>
                      <Grid item xs={6}>
                        <Input name="senderFirstName" />
                      </Grid>
                      <Grid item xs={6}>
                        <Input name="senderLastName" />
                      </Grid>
                      <Grid item xs={6}>
                        <EmailInput name="senderEmail" />
                      </Grid>
                      <Grid item xs={6}>
                        <EmailInput name="receiverEmails" />
                      </Grid>
                      <Grid item xs={6}>
                        <PhoneInput name="phoneNumber" />
                      </Grid>
                      <Grid item xs={12}>
                        <TextArea name="description" />
                      </Grid>
                    </Grid>
                  </SectionBox>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </FormStyled>
    </AnimatedContainer>
  );
}
