import React from "react";
import { FormProvider, FormRules } from "@homesusa/form";
import { PhoneDataForm } from "core/interfaces";

function PhoneFormProvider({
  children,
  rules,
  initialData,
}: {
  children: React.ReactNode;
  rules: FormRules;
  initialData?: PhoneDataForm;
}): JSX.Element {
  return (
    <FormProvider<PhoneDataForm>
      initialFormState={initialData}
      initialFormRules={rules}
    >
      {children}
    </FormProvider>
  );
}

export { PhoneFormProvider };
