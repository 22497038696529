import { FormRules } from "@homesusa/form";
import { quickCreateRules } from "modules/phones/rules";
import { MarketCities, MarketCode } from "@homesusa/core";

export function getFormLeadRules(
  market: MarketCode,
  leadRules: FormRules
): FormRules {
  const marketCities = MarketCities.get(market);

  return {
    ...leadRules,
    city: {
      ...quickCreateRules.city,
      options: marketCities,
    },
  };
}
