import React from "react";
import { Column } from "react-table";
import {
  Format,
  MarketCities,
  MarketCode,
  MarketCodeLabel,
} from "@homesusa/core";
import { Phone } from "core/interfaces";
import { TransactionType, TransactionTypeLabel } from "core/enum";

type phoneColumn = Array<Column<Phone>>;

export const usePhoneGridColumns = (): phoneColumn => {
  const columns: phoneColumn = React.useMemo(
    () => [
      {
        Header: "Row",
        Cell: ({ row }: { row: { index: number } }): number => row.index + 1,
      },
      {
        Header: "Created",
        accessor: (data: Phone): string => Format.DateTime(data.transferedOn),
        id: "transferedOn",
      },
      {
        Header: "MLS #",
        accessor: (data: Phone): string => data.mlsNum,
        id: "mlsNum",
      },
      {
        Header: "Transaction Type",
        accessor: (data: Phone): string =>
          TransactionTypeLabel.get(data.transactionType as TransactionType) ??
          data.transactionType,
      },
      {
        Header: "Market",
        accessor: (data: Phone): string =>
          MarketCodeLabel.get(data.market as MarketCode) ?? data.market,
      },
      {
        Header: "Builder",
        accessor: (data: Phone): string => data.companyName,
        id: "company",
      },
      {
        Header: "City",
        accessor: (data: Phone): string => {
          const marketCities = MarketCities.get(data.market as MarketCode);
          return marketCities
            ? marketCities[data.city] ?? data.city
            : data.city;
        },
      },
      {
        Header: "Subdivision",
        accessor: (data: Phone): string => data.subdivision,
      },
      {
        Header: "Address",
        accessor: (data: Phone): string => data.address,
      },
      {
        Header: "Zip Code",
        accessor: (data: Phone): number => data.zipCode,
      },
      {
        Header: "Caller",
        accessor: (data: Phone): string => Format.PhoneNumber(data.callerId),
        id: "callerId",
      },
      {
        Header: "Transfered To",
        accessor: (data: Phone): string =>
          Format.PhoneNumber(data.transferedTo),
      },
    ],
    []
  );

  return columns;
};
