import React from "react";
import { FormToolbar as HusaFormToobal } from "@homesusa/form";
import { useDocumentTitle } from "@homesusa/layout";
import { EmailActionsGroup } from "../email-actions-group";
import { useSectionTitle } from "core/hooks";
import { EmailContext } from "modules/emails/contexts";

function EmailDetailsToolbar(): JSX.Element {
  const { email } = React.useContext(EmailContext);
  const title = useSectionTitle(email);
  useDocumentTitle(`Email - ${title}`);

  return (
    <HusaFormToobal>
      <HusaFormToobal.Left title={title} />
      <HusaFormToobal.Right>
        <EmailActionsGroup />
      </HusaFormToobal.Right>
    </HusaFormToobal>
  );
}
export default EmailDetailsToolbar;
