import { useMemo } from "react";
import { MarketCodeLabel } from "@homesusa/core";
import { LeadBase } from "core/interfaces";

export const useSectionTitle = (lead: LeadBase): string => {
  return useMemo(() => {
    return `${lead.companyName} - ${MarketCodeLabel.get(lead.market)} - ${
      lead.address
    } - ${lead.mlsNum}`;
  }, [lead.companyName, lead.market]);
};
