import React from "react";
import { FormContext } from "@homesusa/form";

function useFormUpdater(): {
  updateFormByInputName: (inputName: string, payload: unknown) => void;
  updateFormRulesByInputName: (inputName: string, payload: unknown) => void;
} {
  const { formDispatch, formRulesDispatch } = React.useContext(FormContext);

  const updateFormByInputName = (inputName: string, payload: unknown): void => {
    if (formDispatch) {
      formDispatch({
        type: "FormChangeByInputName",
        inputName,
        payload,
      });
    }
  };

  const updateFormRulesByInputName = (
    inputName: string,
    payload: unknown
  ): void => {
    if (formRulesDispatch) {
      formRulesDispatch({
        type: "UpdateByInputName",
        inputName,
        payload,
      });
    }
  };

  return { updateFormByInputName, updateFormRulesByInputName };
}

export default useFormUpdater;
