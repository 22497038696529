import React from "react";
import { useNavigate } from "react-router-dom";
import { isValidGuid } from "@homesusa/core";
import { AppContext } from "@homesusa/layout";
import { EmailContext } from "../contexts";
import { deleteEmail } from "core/services/emails/emails.service";

export const useDeleteEmail = (): (() => void) => {
  const navigate = useNavigate();
  const { email } = React.useContext(EmailContext);
  const { addAlert } = React.useContext(AppContext);

  const onDelete = (): void => {
    if (!isValidGuid(email.id)) {
      return;
    }

    deleteEmail(email.id).then(() => {
      addAlert({
        message: "The email lead was successfully deleted",
        variant: "success",
      });
      navigate(`/lead-emails`);
    });
  };

  return onDelete;
};
