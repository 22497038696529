import { FormRules } from "@homesusa/form";
import { commonInfoRules } from "core/rules";

export const quickCreateRules: FormRules = {
  ...commonInfoRules,
  callerId: {
    label: "Caller",
    required: true,
  },
  transferedTo: {
    label: "Lead Receiver",
    required: true,
  },
};
