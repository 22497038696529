import React from "react";
import { LinearProgress } from "@mui/material";
import { flattenRules, FormErrorsButton, FormStyled } from "@homesusa/form";
import { PhoneContext } from "modules/phones/contexts";
import { PhoneFormProvider } from "modules/phones/contexts/phone-form/phone-form.provider";
import { quickCreateRules } from "modules/phones/rules";
import PhoneDetailsToolbar from "./phone-details-toolbar";
import { PhoneForm } from "./phone-form.component";
import { getFormLeadRules } from "core/utils";

function PhoneFormPanel(): JSX.Element {
  const { phone } = React.useContext(PhoneContext);

  if (!phone) {
    return <LinearProgress />;
  }
  const phoneRules = getFormLeadRules(phone.market, quickCreateRules);
  return (
    <PhoneFormProvider initialData={phone} rules={flattenRules(phoneRules)}>
      <PhoneDetailsToolbar />
      <FormErrorsButton />
      <FormStyled>
        <PhoneForm />
      </FormStyled>
    </PhoneFormProvider>
  );
}

export default PhoneFormPanel;
