import React from "react";
import { Grid } from "@mui/material";
import { Input, Select } from "@homesusa/form";
import { SectionBox } from "@homesusa/core";

export function GeneralSection(): JSX.Element {
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <SectionBox title="General">
          <Grid container>
            <Grid item xs={6}>
              <Select name="transactionType" />
            </Grid>
            <Grid item xs={6}>
              <Input name="mlsNum" disabled />
            </Grid>
            <Grid item xs={6}>
              <Select name="market" disabled />
            </Grid>
            <Grid item xs={6}>
              <Input name="companyName" disabled />
            </Grid>
          </Grid>
        </SectionBox>
      </Grid>
    </React.Fragment>
  );
}
