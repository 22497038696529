import React from "react";
import { LinearProgress } from "@mui/material";
import { PhoneDataForm } from "core/interfaces";
import { getPhone } from "core/services/phones/phones.service";
import { PhoneContext } from "./phone.context";

export function PhoneProvider({
  phoneId,
  children,
}: {
  phoneId: string;
  children: React.ReactNode;
}): JSX.Element {
  const [phone, setPhone] = React.useState<PhoneDataForm | null>(null);

  const fetchPhoneData = React.useCallback(() => {
    getPhone(phoneId).then((data: PhoneDataForm) => {
      setPhone(data);
    });
  }, [phoneId]);

  React.useEffect(() => {
    fetchPhoneData();
  }, [fetchPhoneData]);

  if (!phone) {
    return <LinearProgress />;
  }

  return (
    <PhoneContext.Provider
      value={{
        phone,
        getPhone: fetchPhoneData,
      }}
    >
      {children}
    </PhoneContext.Provider>
  );
}
