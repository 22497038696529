import React from "react";
import { useNavigate } from "react-router-dom";
import { isValidGuid } from "@homesusa/core";
import { AppContext } from "@homesusa/layout";
import { PhoneContext } from "../contexts";
import { deletePhone } from "core/services/phones/phones.service";

export const useDeletePhone = (): (() => void) => {
  const navigate = useNavigate();
  const { phone } = React.useContext(PhoneContext);
  const { addAlert } = React.useContext(AppContext);

  const onDelete = (): void => {
    if (!isValidGuid(phone.id)) {
      return;
    }

    deletePhone(phone.id).then(() => {
      addAlert({
        message: "The phone lead was successfully deleted",
        variant: "success",
      });
      navigate(`/`);
    });
  };

  return onDelete;
};
