import React from "react";
import { Grid } from "@mui/material";
import { SectionBox } from "@homesusa/core";
import { Input, NumberInput, Select } from "@homesusa/form";

export function AddressSection(): JSX.Element {
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <SectionBox title="Address">
          <Grid container>
            <Grid item xs={6}>
              <Input name="subdivision" />
            </Grid>
            <Grid item xs={6}>
              <Input name="address" />
            </Grid>
            <Grid item xs={6}>
              <Select name="city" />
            </Grid>
            <Grid item xs={6}>
              <NumberInput name="zipCode" />
            </Grid>
          </Grid>
        </SectionBox>
      </Grid>
    </React.Fragment>
  );
}
