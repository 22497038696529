import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { isValidGuid } from "@homesusa/core";
import { EmailContext } from "modules/emails/contexts";
import { useDeleteEmail } from "modules/emails/hooks";

export function DeleteEmailButton(): JSX.Element {
  const { email } = React.useContext(EmailContext);
  const [showModal, setShowModal] = React.useState(false);
  const handleCloseModal = (): void => setShowModal(false);
  const handleShowModal = (): void => setShowModal(true);
  const onDelete = useDeleteEmail();

  const isValidEmailId = React.useMemo((): boolean => {
    return !!email && isValidGuid(email.id);
  }, [email]);

  return (
    <React.Fragment>
      <Button
        onClick={handleShowModal}
        disabled={!isValidEmailId}
        startIcon={<Delete />}
        className="danger"
      >
        Delete
      </Button>
      <Dialog open={showModal} onClose={handleCloseModal}>
        <DialogTitle>Delete Email</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this email?</p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(): void => {
              handleCloseModal();
              onDelete();
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
