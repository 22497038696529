import React from "react";
import { MarketCode, MarketCodeLabel } from "@homesusa/core";
import { AuthCompanyContext } from "@homesusa/auth";
import { TransactionType, TransactionTypeLabel } from "core/enum";
import { FiltersLeadGrid } from "core/interfaces";

interface UseFiltersGrid {
  filters: FiltersLeadGrid;
  setFilters: React.Dispatch<React.SetStateAction<FiltersLeadGrid>>;
  transactionTypeOptions: Record<string, string>;
  marketOptions: Record<string, string>;
  companyOptions: Record<string, string>;
}

export const useFiltersGrid = (): UseFiltersGrid => {
  const date = new Date();
  const [filters, setFilters] = React.useState<FiltersLeadGrid>({
    transactionType: TransactionType.Sale,
    market: MarketCode.Sabor,
    companyId: undefined,
    startDate: date,
    endDate: date,
  });

  const { userCompanies } = React.useContext(AuthCompanyContext);

  const transactionTypeOptions = Object.values(TransactionType).reduce(
    (current, transactionType) => ({
      ...current,
      [transactionType.toString()]: TransactionTypeLabel.get(transactionType),
    }),
    {}
  );

  const marketOptions = Object.values(MarketCode).reduce(
    (current, marketSelected) => ({
      ...current,
      [marketSelected.toString()]: MarketCodeLabel.get(marketSelected),
    }),
    {}
  );

  const companiesInMarket = React.useMemo(
    () =>
      userCompanies?.filter(
        (company) => company.marketCode === filters.market
      ) || [],
    [filters.market, userCompanies]
  );

  const companyOptions = companiesInMarket.reduce(
    (current, company) => ({
      ...current,
      [company.id]: company.name,
    }),
    { All: "All" }
  );

  React.useEffect(() => {
    if (companiesInMarket.length > 0) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        companySelected: companiesInMarket[0].id,
      }));
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        companySelected: undefined,
      }));
    }
  }, [companiesInMarket]);

  return {
    filters,
    setFilters,
    transactionTypeOptions,
    marketOptions,
    companyOptions,
  };
};
