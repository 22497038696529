import { useParams } from "react-router-dom";
import { EmailProvider } from "../contexts";
import EmailFormPanel from "../components/form-update/form.component";
function EmailDetails(): JSX.Element {
  const params = useParams<{ id: string }>();
  if (params.id == null) {
    return <></>;
  }
  return (
    <EmailProvider emailId={params.id}>
      <EmailFormPanel />
    </EmailProvider>
  );
}
export default EmailDetails;
