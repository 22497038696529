import { getOptionsFromEnumAndMap } from "@homesusa/core";
import { FormRules } from "@homesusa/form";
import { commonInfoRules } from "core/rules";
import { EmailSource, EmailSourceLabel } from "../enums";

export const quickCreateRules: FormRules = {
  ...commonInfoRules,
  source: {
    label: "Source",
    required: true,
    options: getOptionsFromEnumAndMap(
      Object.values(EmailSource),
      EmailSourceLabel
    ),
  },
  description: {
    label: "Description",
    required: true,
  },
  receiverEmails: {
    label: "Lead Receiver",
    required: true,
  },
  phoneNumber: {
    label: "Lead Phone",
    required: true,
  },
  senderEmail: {
    label: "Sender Email",
    required: true,
  },
};
