import React from "react";
import { FormToolbar as HusaFormToobal } from "@homesusa/form";
import { useDocumentTitle } from "@homesusa/layout";
import { PhoneActionsGroup } from "../phone-actions-group";
import { PhoneContext } from "modules/phones/contexts";
import { useSectionTitle } from "core/hooks";

function PhoneDetailsToolbar(): JSX.Element {
  const { phone } = React.useContext(PhoneContext);
  const title = useSectionTitle(phone);
  useDocumentTitle(`Phone - ${title}`);

  return (
    <HusaFormToobal>
      <HusaFormToobal.Left title={title} />
      <HusaFormToobal.Right>
        <PhoneActionsGroup />
      </HusaFormToobal.Right>
    </HusaFormToobal>
  );
}
export default PhoneDetailsToolbar;
