import React from "react";
import { LinearProgress } from "@mui/material";
import { flattenRules, FormErrorsButton, FormStyled } from "@homesusa/form";
import { EmailContext } from "modules/emails/contexts";
import { EmailFormProvider } from "modules/emails/contexts/email-form/email-form.provider";
import { quickCreateRules } from "modules/emails/rules";
import EmailDetailsToolbar from "./email-details-toolbar";
import { EmailForm } from "./email-form.component";
import { getFormLeadRules } from "core/utils";
function EmailFormPanel(): JSX.Element {
  const { email } = React.useContext(EmailContext);
  if (!email) {
    return <LinearProgress />;
  }
  const emailRules = getFormLeadRules(email.market, quickCreateRules);

  return (
    <EmailFormProvider initialData={email} rules={flattenRules(emailRules)}>
      <EmailDetailsToolbar />
      <FormErrorsButton />
      <FormStyled>
        <EmailForm />
      </FormStyled>
    </EmailFormProvider>
  );
}
export default EmailFormPanel;
