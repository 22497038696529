import React from "react";
import { Save as SaveIcon } from "@mui/icons-material";
import { useHasRole } from "@homesusa/auth";
import { SubmitButton } from "@homesusa/core";
import { DeletePhoneButton } from "./delete-phone-button.component";
import { useUpdatePhone } from "modules/phones/hooks";

export function PhoneActionsGroup(): JSX.Element {
  const { isMlsAdministrator } = useHasRole();
  const onSave = useUpdatePhone();

  return (
    <React.Fragment>
      {isMlsAdministrator && (
        <SubmitButton
          onClick={onSave}
          buttonProps={{
            variant: "contained",
            color: "primary",
            startIcon: <SaveIcon />,
          }}
        >
          Save
        </SubmitButton>
      )}
      {isMlsAdministrator && <DeletePhoneButton />}
    </React.Fragment>
  );
}
