import React from "react";
import { LinearProgress } from "@mui/material";
import { EmailDataForm } from "core/interfaces";
import { getEmail } from "core/services/emails/emails.service";
import { EmailContext } from "./email.context";
export function EmailProvider({
  emailId,
  children,
}: {
  emailId: string;
  children: React.ReactNode;
}): JSX.Element {
  const [email, setEmail] = React.useState<EmailDataForm | null>(null);
  const fetchEmailData = React.useCallback(() => {
    getEmail(emailId).then((data: EmailDataForm) => {
      setEmail(data);
    });
  }, [emailId]);

  React.useEffect(() => {
    fetchEmailData();
  }, [fetchEmailData]);

  if (!email) {
    return <LinearProgress />;
  }
  return (
    <EmailContext.Provider
      value={{
        email,
        getEmail: fetchEmailData,
      }}
    >
      {children}
    </EmailContext.Provider>
  );
}
