import { useParams } from "react-router-dom";
import { PhoneProvider } from "../contexts";
import PhoneFormPanel from "../components/form-update/form.component";

function PhoneDetails(): JSX.Element {
  const params = useParams<{ id: string }>();

  if (params.id == null) {
    return <></>;
  }

  return (
    <PhoneProvider phoneId={params.id}>
      <PhoneFormPanel />
    </PhoneProvider>
  );
}

export default PhoneDetails;
