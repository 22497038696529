import axios from "axios";
import { GridResponse } from "@homesusa/grid-table";
import { Email, EmailDataForm } from "core/interfaces";
import { FetchDataGrid } from "core/interfaces/services";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/`;

const getActiveEmails = async (
  params: FetchDataGrid
): Promise<GridResponse<Email>> => {
  const response = await axios.get<GridResponse<Email>>(
    `${apiUrl}email-leads`,
    {
      params,
    }
  );
  return response.data;
};
const createEmail = async (email: EmailDataForm): Promise<string> => {
  const response = await axios.post(`${apiUrl}email-leads`, email);
  return response.data;
};
const getEmail = async (id: string): Promise<EmailDataForm> => {
  const response = await axios.get(`${apiUrl}email-leads/${id}`);
  return response.data as EmailDataForm;
};
const updateEmail = async (email: EmailDataForm): Promise<EmailDataForm> => {
  const { id, ...emailDataForm } = email;
  const response = await axios.put(`${apiUrl}email-leads/${id}`, emailDataForm);
  return response.data;
};
const deleteEmail = async (id: string): Promise<void> =>
  axios.delete(`${apiUrl}email-leads/${id}`).then(() => {
    return Promise.resolve();
  });
export { getActiveEmails, getEmail, createEmail, updateEmail, deleteEmail };
